<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/admin/dashboard">
                <i class="material-icons">dashboard</i>
                <span>Dashboard</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/vehicles">
                <i class="material-icons">drive_eta</i>
                <span>Fahrzeuge</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/locations">
                <i class="material-icons">gps_fixed</i>
                <span>Standorte</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/customers">
                <i class="material-icons">group</i>
                <span>Kunden</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/admin/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">
          Test
        </div>
      </div>

    </div>

  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'admin_reservations',
  data () {
    return {}
  },
  methods: {
    get_locations() {},
  },
  mounted () {}
}
</script>

<style lang="scss">

</style>
